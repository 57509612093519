import { render, staticRenderFns } from "./长电wifi--38版.vue?vue&type=template&id=53abdd86&scoped=true&"
import script from "./长电wifi--38版.vue?vue&type=script&lang=js&"
export * from "./长电wifi--38版.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53abdd86",
  null
  
)

export default component.exports